#mainMenu.desktop {
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#mainMenu.desktop .menuBlock {
  display: flex;
}

#mainMenu.desktop .menuBlock .menuItem {
  cursor: pointer;
  color: #fff;
  align-items: center;
  margin-right: 24px;
  text-decoration: none;
  display: flex;
}

#mainMenu.desktop .menuBlock .menuItem.active {
  color: #e52c34 !important;
}

#mainMenu.desktop .menuBlock .menuItem .menuTitle {
  padding-bottom: 8px;
  position: relative;
  top: 4px;
}

#mainMenu.desktop .menuBlock .menuItem .subMenu {
  color: #25408e;
  background: #fff;
  border-radius: 4px;
  flex-direction: column;
  padding: 8px 0;
  display: none;
  position: absolute;
  top: 50px;
  box-shadow: 0 14px 24px #58629833;
}

#mainMenu.desktop .menuBlock .menuItem .subMenu .menuItem {
  color: #25408e;
  margin: 0;
  padding: 4px 16px;
  text-decoration: none;
}

#mainMenu.desktop .menuBlock .menuItem .subMenu .menuItem:hover {
  background-color: #e9eefb;
}

#mainMenu.desktop .menuBlock .menuItem:hover .subMenu {
  display: flex;
}

#mainMenu.desktop .menuBlock .menuItem:hover.withSubmenu:after {
  transform: rotate(-90deg);
}

#mainMenu.desktop .menuBlock .menuItem.withSubmenu:after {
  content: " ";
  contain: content;
  background-image: url("bracket.32b59a6b.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
  margin-left: 4px;
  transition: transform .2s ease-in-out;
  transform: rotate(90deg);
}

#mainMenu.desktop .buttonBlock {
  display: flex;
}

#mainMenu.desktop .buttonBlock .menuButton {
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.top.white #mainMenu.desktop .menuItem.withSubmenu:after {
  background-image: url("bracket_blue.cb398820.svg");
}

#mainMenu.mobile {
  color: #fff;
}

#mainMenu.mobile .menuIcon {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-image: url("hamburger.99443820.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

#mainMenu.mobile .menuBlock {
  display: none;
}

#mainMenu.mobile .menuBlock .menuItem {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  padding: 8px 0;
  font-size: 20px;
  text-decoration: none;
  display: block;
}

#mainMenu.mobile .menuBlock .menuItem.active {
  color: #e52c34 !important;
}

#mainMenu.mobile .menuBlock .menuItem .subMenu {
  color: #a1b2e1;
  margin-top: 8px;
  margin-left: 16px;
  display: none;
}

#mainMenu.mobile .menuBlock .menuItem .subMenu .menuItem {
  color: #a1b2e1;
  text-decoration: none;
}

#mainMenu.mobile .menuBlock .menuItem.active {
  position: relative;
}

#mainMenu.mobile .menuBlock .menuItem.active .subMenu {
  display: block;
}

#mainMenu.mobile .menuBlock .menuItem.active.withSubmenu:after {
  contain: content;
  content: "—";
  color: #fff;
  position: absolute;
  top: 6px;
  right: -3px;
}

#mainMenu.mobile .buttonBlock {
  gap: 16px;
  display: none;
}

#mainMenu.mobile .buttonBlock a {
  text-align: center;
  width: 100%;
  margin-right: 0;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 24px;
}

#mainMenu.mobile.active {
  background-color: #0a2c73;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  display: flex;
  position: fixed;
  inset: -16px 0;
}

#mainMenu.mobile.active .menuIcon {
  background-image: url("close.83d6b702.svg");
  align-self: end;
  position: absolute;
  top: 35px;
  right: 25px;
}

#mainMenu.mobile.active .menuBlock {
  flex-basis: 100%;
  margin-top: 49px;
  display: block;
}

#mainMenu.mobile.active .buttonBlock {
  display: flex;
}

.top.white #mainMenu.mobile .menuIcon {
  background-image: url("hamburger_blue.c97f990f.svg");
}

.top.white #mainMenu.mobile.active .menuIcon {
  background-image: url("close.83d6b702.svg");
}
/*# sourceMappingURL=index.8fa41d88.css.map */
