@import '/packages/site/src/public/styles/colors.scss';

#mainMenu.desktop {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;

  .menuBlock {
    display: flex;

    .menuItem {
      margin-right: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $ui_01;

      &.active {
        color: $accent_01 !important;
      }

      .menuTitle {
        padding-bottom: 8px;
        position: relative;
        top: 4px;

      }

      .subMenu {
        display: none;
        position: absolute;
        flex-direction: column;
        background: $ui_01;
        box-shadow: 0px 14px 24px rgba(88, 98, 152, 0.2);
        border-radius: 4px;
        color: $text_02;
        padding: 8px 0;
        top: 50px;

        .menuItem {
          padding: 4px 16px;
          margin: 0;
          color: $text_02;
          text-decoration: none;

          &:hover {
            background-color: $ui_02;
          }
        }
      }

      &:hover {
        .subMenu {
          display: flex;
        }

        &.withSubmenu::after {
          transform: rotate(-90deg);
        }
      }

      &.withSubmenu::after {
        content: ' ';
        contain: content;
        width: 8px;
        height: 8px;
        background-image: url(/packages/site/src/assets/images/bracket.svg);
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(90deg);
        margin-left: 4px;
        transition: transform 0.2s ease-in-out;
      }
    }
  }


  .buttonBlock {
    display: flex;

    .menuButton {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 4px 12px;
    }
  }
}

.top.white #mainMenu.desktop .menuItem.withSubmenu::after {
  background-image: url(/packages/site/src/assets/images/bracket_blue.svg);
}

#mainMenu.mobile {
  color: $ui_01;

  .menuIcon {
    width: 20px;
    height: 20px;
    background-image: url(/packages/site/src/assets/images/hamburger.svg);
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .menuBlock {
    display: none;

    .menuItem {
      padding: 8px 0;
      display: block;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      color: $ui_01;
      text-decoration: none;
      font-size: 20px;

      &.active {
        color: $accent_01 !important;
      }

      .subMenu {
        display: none;
        display: block;
        color: $ui_04;
        margin-left: 16px;
        margin-top: 8px;
        display: none;

        .menuItem {
          color: $ui_04;
          text-decoration: none;
        }
      }

      &.active {
        position: relative;

        .subMenu {
          display: block;
        }
        
        &.withSubmenu::after {
          contain: content;
          content: "—";
          position: absolute;
          right: -3px;
          top: 6px;
          color: $ui_01;
        }
      }
    }

  }

  .buttonBlock {
    display: none;
    gap: 16px;

    a {
      text-align: center;
      width: 100%;
      margin-right: 0px;
      padding: 4px 12px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.active {
    background-color: $ui_06;
    position: fixed;
    inset: -16px 0 -16px;
    padding: 32px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    .menuIcon {
      background-image: url(/packages/site/src/assets/images/close.svg);
      align-self: end;
      position: absolute;
      top: 35px;
      right: 25px;
    }

    .menuBlock {
      display: block;
      flex-basis: 100%;
      margin-top: 49px;
    }

    .buttonBlock {
      display: flex;
    }
  }
}

.top.white #mainMenu.mobile {
  .menuIcon {
    background-image: url(/packages/site/src/assets/images/hamburger_blue.svg);
  }

  &.active .menuIcon {
    background-image: url(/packages/site/src/assets/images/close.svg);
  }
}